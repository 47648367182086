<template>
  <div>
    <div class="forgot-password">
      <div class="content">
        <div class="card-form">
          <div class="title-form text-center">
            <div class="welcome-text">Reset Password</div>
            <div class="desc-text">
              Masukan alamat email yang sudah terdaftar, kami akan mengirimkan
              kode OTP.
            </div>
          </div>
          <div class="logo-form text-center mt-4 mb-4">
            <img src="../assets/img/icon-key.svg" alt="" />
          </div>
          <form @submit.prevent="onSubmit()">
            <div class="form-group">
              <div class="input-group mb-3">
                <input
                  type="text"
                  name="email"
                  v-model="formData.email"
                  :class="{ 'is-invalid': formError && formError.email }"
                  @change="checkForm('email')"
                  class="form-control"
                  placeholder="Masukan alamat email"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                />
                <div class="form-error" v-if="formError && formError.email">
                  {{ formError.email }}
                </div>
              </div>
            </div>
            <button
              class="btn btn-form mt-2 mb-4 d-block"
              id="submitForm"
              :disabled="isSubmit"
            >
              <i class="fas fa-spinner fa-spin" v-if="isSubmit"></i>
              <span class="btn-login">Kirim</span>
            </button>
          </form>
          <div class="text-center">
            <span class="text-link"
              >Kembali ke halaman <router-link to="/">Login </router-link></span
            >
          </div>
        </div>
        <div class="element-img">
          <img src="../assets/img/ball.svg" alt="" />
          <img src="../assets/img/square-3d.svg" alt="" />
          <img src="../assets/img/square.svg" alt="" />
          <img src="../assets/img/square.svg" alt="" />
          <img src="../assets/img/elipse.svg" alt="" class="" />
          <img src="../assets/img/elipse.svg" alt="" class="" />
          <div class="title-brand">Finanta</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post_ForgotPassword } from "../actions/auth";
import { checkRules } from "../helper";
export default {
  name: "login",
  components: {},
  data() {
    return {
      formData: {
        email: "",
      },
      formError: [],
      rules: {
        email: {
          required: true,
          email: true,
          msg: "Email is required",
          msg_email: "You have entered an invalid email address",
        },
      },
      isSubmit: false,
    };
  },
  methods: {
    showWarningAlert(msg = "", callaback) {
      var _msg = msg ? msg : "Terjadi kesalahan, silakan ulangi kembali";
      this.$swal({
        html:
          '<div style="color:#222222; font-size:20px; font-weight:500;" class="mt-3 mb-3">GAGAL!</div>' +
          '<div style="color:#263238" font-size:12px;">' +
          _msg +
          "</div>",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonColor: "#fff",
        cancelButtonText: "Tutup",
      }).then(function () {
        if (typeof callaback === "function") {
          callaback();
        }
      });
    },
    showAlert(msg, callaback) {
      var _msg = msg ? msg : "Kode otp terkirim, mohon cek kotak masuk";
      // Use swtalert2
      // Kode OTP terkirim ke nomor telepon <span style="color:#0E867F"> 0822******09 </span> mohon cek kotak masuk
      this.$swal({
        html:
          '<div style="color:#222222; font-size:20px; font-weight:500;" class="mt-3 mb-3">OTP Terkirim!</div>' +
          '<div style="color:#263238" font-size:12px;">' +
          _msg +
          "</div>",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: "#fff",
        confirmButtonText: "Lanjut",
      }).then(function () {
        if (typeof callaback === "function") {
          callaback();
        }
        // window.location = "/verifikasi";
      });
    },
    checkForm: async function (tipe) {
      this.rules[tipe].changed = true;
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    convertEmailText: (text) => {
      var split = text.split("@");
      var last = split[split.length - 1];
      delete split[split.length - 1];
      var text_email = split.join("");
      return text_email.substr(0, 4) + "*******@" + last;
    },
    onSubmit: async function () {
      var check = await checkRules(this.rules, this.formData);
      if (!check) {
        this.formError = check.error;
      } else {
        this.isSubmit = true;
        await post_ForgotPassword(
          this.formData,
          () => {
            this.isSubmit = false;
            this.showAlert(
              `Kode OTP terkirim ke alamat email <span style="color:#0E867F"> ${this.convertEmailText(
                this.formData.email
              )} </span> mohon cek kotak masuk atau spam`,
              () => {
                localStorage.setItem("_Fem", this.formData.email);
                this.$router.push({ name: "verifikasi" });
              }
            );
          },
          () => {
            this.isSubmit = false;
            this.showWarningAlert();
            // console.log(err)
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.logo-form img {
  width: 150px;
}

.title-brand {
  position: absolute;
  color: #fff;
  font-size: 54px;
  font-weight: bold;
  top: 2%;
  left: 5%;
}

.element-img img:nth-child(1) {
  position: absolute;
  top: 10%;
  left: 30%;
  z-index: 1;
}

.element-img img:nth-child(2) {
  position: absolute;
  top: 60%;
  left: 60%;
  z-index: 1;
}

.element-img img:nth-child(3) {
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 0;
}

.element-img img:nth-child(4) {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.element-img img:nth-child(5) {
  position: absolute;
  left: -150px;
  top: 60%;
  z-index: 1;
}

.element-img img:nth-child(6) {
  position: absolute;
  right: -150px;
  top: 10%;
  z-index: 1;
}

.text-link {
  color: #263238;
}

.text-link a {
  text-decoration: none;
  color: #40ddd4;
}

.title-form .welcome-text {
  font-size: 28px;
  color: var(--text);
  font-weight: 600;
  text-align: center;
}

.title-form .desc-text {
  color: #898989;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.forgot-password {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
}

.content {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.forgot-password .card-form {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  padding: 50px 50px;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  box-shadow: 0px 0px 48px -20px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.forgot-password .form-control {
  color: var(--text);
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  padding: 9px 16px;
  font-family: "Poppins", sans-serif;
}

.swal2-popup {
  width: 24em !important;
}

.btn-form {
  background-color: #40ddd4;
  width: 100%;
  color: #fff;
}

.forgot-password .btn-form:hover {
  color: #fff !important;
}

/* .btn:hover {
    color: #fff !important;
  } */
</style>
